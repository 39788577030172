// Environment variables
// NextJS doesn't support destructuring, so we have to use `process.env.` directly
import { FetchPolicy } from '@apollo/client'

export const GRAPHCMS_PRIVATE_TOKEN = process.env.GRAPHCMS_TOKEN || ''

export const MIN_GUESTS = 5
export const MAX_GUESTS = 100

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

// Public tokens
export const GRAPHCMS_URL = process.env.NEXT_PUBLIC_GRAPHCMS_URL || ''
export const GRAPHCMS_PUBLIC_TOKEN = process.env.NEXT_PUBLIC_GRAPHCMS_TOKEN || ''

export const STATIC_FETCH_POLICY: FetchPolicy =
  process.env.CACHE_STATIC_CONTENT_DEV === 'true' ? 'cache-first' : 'no-cache'

export const TYPEFORM = {
  VENUE_ID: process.env.NEXT_PUBLIC_VENUE_TYPEFORM_ID || '',
  VENUE_ID_DE: process.env.NEXT_PUBLIC_VENUE_TYPEFORM_ID_DE || '',
  REFERRAL_ID: process.env.NEXT_PUBLIC_REFERRAL_TYPEFORM_ID || '',
  REFERRAL_ID_DE: process.env.NEXT_PUBLIC_REFERRAL_TYPEFORM_ID_DE || '',
  CONTACT_ID: process.env.NEXT_PUBLIC_CONTACT_TYPEFORM_ID || '',
  CONTACT_ID_DE: process.env.NEXT_PUBLIC_CONTACT_TYPEFORM_ID_DE || '',
  BECOME_PARTNER_ID: process.env.NEXT_PUBLIC_BECOME_PARTNER_TYPEFORM_ID || '',
  REQUEST_QUOTE_ID: process.env.NEXT_PUBLIC_REQUEST_QUOTE_TYPEFORM_ID || '',
  REQUEST_QUOTE_ID_DE: process.env.NEXT_PUBLIC_REQUEST_QUOTE_TYPEFORM_ID_DE || '',
}

export const APP_ROUTES = {
  HOME: '/',
  VENUES: '/venues',
  VENUES_SEARCH: '/venues/s',
  VENUE: '/venue',
  ABOUT: '/about',
  BLOG: '/blog',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  IMPRINT: '/imprint',
  SUSTAINABILITY: '/sustainability',
  REFERRAL: '/referral',
}

export const API_ROUTES = {
  NEWSLETTER: '/api/newsletter',
}
